import React, {useEffect, useState, lazy, Suspense} from 'react'
import DashboardCardComponent from '../../DashboardCardComponent/DashboardCardComponent';


const ActivationCounts = lazy(() => import("./ActivationCounts/ActivationCounts"));
const ActivationMap = lazy(() => import("./ActivationMap/ActivationMap"));
const ActivationBrandsBlock = lazy(() => import("./ActivationBrandsBlock/ActivationBrandsBlock"));
const ActivationAgenciesBlock = lazy(() => import("./ActivationAgenciesBlock/ActivationAgenciesBlock"));
const ActivationLocationBlock = lazy(() => import("./ActivationLocationBlock/ActivationLocationBlock"));
const ActivationTimelineBlock = lazy(() => import("./ActivationTimelineBlock/ActivationTimelineBlock"));
const ActivationClientsBlock = lazy(() => import("./ActivationClientsBlock/ActivationClientsBlock"));


export function ActivationDashboard() {

    const [activationCount, setActivationCount] = useState({})
    const [pageLoading, setPageLoading] = useState(false)
    const [activationLocationInfo, setLocationActivationInfo] = useState([])
    const [activationPerCommissioningAgency, setActivationPerCommissioningAgency] = useState([])
    const [activationPerBrand, setActivationPerBrand] = useState([])
    const [activationPerProject, setActivationPerProject] = useState([])
    const [activationPerWeek, setActivationPerWeek] = useState([])
    const [activationPerMonth, setActivationPerMonth] = useState([])
    const [activationCostPerBrand, setActivationCostPerBrand] = useState([])
    const [activationCostPerAgency, setActivationCostPerAgency] = useState([])
    const [activationPerCounty, setActivationPerCounty] = useState([])
    const [activationPerRegion, setActivationPerRegion] = useState([])
    const [activationPerActivatingAgency, setActivationPerActivatingAgency] = useState([])
    const [activationPerHandlingAgency, setActivationPerHandlingAgency] = useState([])
    const [beysixHandlingAgencyData, setBeysixHandlingAgencyData] = useState([])
    const [years, setYears] = useState([])

    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
    const [widgetLoading, setWidgetLoading] = useState(true)

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'end'}}>
          <div className="mb-3" style={{width: '30%', background: '#fff', padding: '15px', borderRadius: '5px'}}>
              <DashboardCardComponent isLoading={widgetLoading} >
              
                  <label className="form-label fwt-bold fs-6">Filter By Year:</label>
                  <select className="form-select form-select-solid" value={selectedYear} onChange={(evt) => {
                      // setPageLoading(true)
                      const value = evt.target.value;
                      setSelectedYear(value)
                  }}>
                      <option value="all">-- Select Option --</option>
                      {years.map((year) =><option value={year}>{year}</option>)}
                  </select>
              </DashboardCardComponent>
          </div>
      </div>
      <Suspense fallback={null}>
            <ActivationCounts year={selectedYear} setYears={setYears} setWidgetLoading={setWidgetLoading} />
      </Suspense>
      <Suspense fallback={null}>
            <ActivationClientsBlock year={selectedYear} />
      </Suspense>
      <Suspense fallback={null}>
            <ActivationMap year={selectedYear} />
      </Suspense>
      <Suspense fallback={null}>
            <ActivationAgenciesBlock year={selectedYear} />
      </Suspense>
      <Suspense fallback={null}>
            <ActivationBrandsBlock year={selectedYear} />
      </Suspense>
      <Suspense fallback={null}>
            <ActivationLocationBlock year={selectedYear} />
      </Suspense>
      <Suspense fallback={null}>
            <ActivationTimelineBlock year={selectedYear} />
      </Suspense>
    </>
  )
}
