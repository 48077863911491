import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import api from '../../../store/api'
import { useParams } from 'react-router'
import parseTimeStamp from '../../Utilities/parseTimeStamp'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useNavigate } from 'react-router'

export default function ActivationReceiptTab() {

    const [receiptNotes, setReceiptNotes] = useState([])
    
    const {id} = useParams()

    const navigate = useNavigate(null)

    const handleReceival = () => {
        navigate(`/activation-management/detailed-activation/receival/${id}`)
    }

    const token = useSelector((state) => state.loginSlice.token);
    const [isOpen, setIsOpen] = useState(false);
    const [currentAssetIndex, setCurrentAssetIndex] = useState(null);
    const [lightboxImageIndex, setLightboxImageIndex] = useState(0);
    const [allItemsReceived, setAllItemsReceived] = useState(false)

    const handleAllItemsReceived = (receiptes) => {
      let flag = true
      for (let i = 0; i < receiptes.length; i++) {
        const air = receiptes[i].all_items_received
        if (air === false) {
          flag = false
        }
      }
      setAllItemsReceived(flag)
    }

    const getReceiptNotes = () => {
        api.get(`/api/activations/get-receipt-notes/${id}/`,
            {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data
                    if (data){
                        setReceiptNotes(data.data)
                        handleAllItemsReceived(data.data)
                    }
                }
            })
            .catch((error) => {
                throw new Error('Error getting data.')
            })
    }

    const [clause, setClause] = useState('receipt')

    useEffect(() => {
        getReceiptNotes()
    }, [])
  return (
    <>
    <div style={{textAlign: 'center'}}>
        <h2>{receiptNotes[0]?.receipt_name}</h2>
    </div>
    {receiptNotes.length > 0 &&
  receiptNotes.map((receiptNote, noteIndex) => (
    <div
      className="card paper-table hover-card"
      key={noteIndex}
      style={{ marginTop: "30px", padding: "50px 125px" }}
    >
      {receiptNote.receipt_checklist && (
        <table>
          <thead>
            <tr>
              <th className="min-w-125px">Asset</th>
              <th className="min-w-250px">Items</th>
              <th className="min-w-125px">Condition Out</th>
              <th className="min-w-125px">Condition In</th>
              <th className="min-w-125px">Dispatch Images</th>
              <th className="min-w-125px">Receipt Images</th>
            </tr>
          </thead>
          <tbody>
            {receiptNote.receipt_checklist &&
              receiptNote.receipt_checklist.map((asset, assetIndex) => (
                <React.Fragment key={assetIndex}>
                  {/* First Row: Asset Name and First Item */}
                  <tr>
                    <td
                      rowSpan={asset.item_serials.length}
                      style={{ verticalAlign: "top" }}
                    >
                      {asset.asset_name}
                    </td>
                    <td>{asset.item_serials[0]?.serial_name}</td>
                    <td>{asset.item_serials[0]?.serial_condition_out}</td>
                    <td>{asset.item_serials[0]?.serial_condition_in}</td>
                    <td rowSpan={asset.item_serials.length}>
                      {asset.media?.length > 0 && (
                        <a
                          className="menu-link px-3 menu-rounded menu-gray-800 menu-state-bg-light-primary ftw-bold"
                          style={{ color: "#fa890f" }}
                          onClick={() => {
                            // Unique lightbox state per note
                            setClause('receipt')
                            setCurrentAssetIndex(assetIndex); // Track the current asset
                            setLightboxImageIndex(0); // Start with the first image
                            setIsOpen({ ...isOpen, [noteIndex]: true }); // Open the lightbox for this note
                          }}
                        >
                        View Images
                        </a>
                      )}
                    </td>
                    <td rowSpan={asset.item_serials.length}>
                      {asset.receipt_media?.length > 0 && (
                        <a
                          className="menu-link px-3 menu-rounded menu-gray-800 menu-state-bg-light-primary ftw-bold"
                          style={{ color: "#fa890f" }}
                          onClick={() => {
                            // Unique lightbox state per note
                            setClause('receipt')
                            setCurrentAssetIndex(assetIndex); // Track the current asset
                            setLightboxImageIndex(0); // Start with the first image
                            setIsOpen({ ...isOpen, [noteIndex]: true }); // Open the lightbox for this note
                          }}
                        >
                        View Images
                        </a>
                      )}
                    </td>
                  </tr>
                  {/* Additional Rows: Remaining Items */}
                  {asset.item_serials.slice(1).map((item, itemIndex) => (
                    <tr key={itemIndex}>
                      <td>{item.serial_name}</td>
                      <td>{item.serial_condition_out}</td>
                      <td>{item.serial_condition_in}</td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
          </tbody>
        </table>
      )}
      {/* Lightbox for this receipt note */}
      {isOpen[noteIndex] && currentAssetIndex !== null && (
        clause === 'receipt' ? 
        <Lightbox
          mainSrc={
            receiptNote.receipt_checklist[currentAssetIndex]?.media[
              lightboxImageIndex
            ]?.media_path
          }
          nextSrc={
            receiptNote.receipt_checklist[currentAssetIndex]?.media[
              (lightboxImageIndex + 1) %
                receiptNote.receipt_checklist[currentAssetIndex]?.media?.length
            ]?.media_path
          }
          prevSrc={
            receiptNote.receipt_checklist[currentAssetIndex]?.media[
              (lightboxImageIndex +
                receiptNote.receipt_checklist[currentAssetIndex]?.media
                  ?.length -
                1) %
                receiptNote.receipt_checklist[currentAssetIndex]?.media?.length
            ]?.media_path
          }
          onCloseRequest={() =>
            setIsOpen({ ...isOpen, [noteIndex]: false }) // Close lightbox for this note
          }
          onMovePrevRequest={() =>
            setLightboxImageIndex(
              (lightboxImageIndex +
                receiptNote.receipt_checklist[currentAssetIndex]?.media
                  ?.length -
                1) %
                receiptNote.receipt_checklist[currentAssetIndex]?.media?.length
            )
          }
          onMoveNextRequest={() =>
            setLightboxImageIndex(
              (lightboxImageIndex + 1) %
                receiptNote.receipt_checklist[currentAssetIndex]?.media?.length
            )
          }
          imageCaption={
            <div style={{ padding: "10px", color: "#fff" }}>
              {
                receiptNote.receipt_checklist[currentAssetIndex]?.media[
                  lightboxImageIndex
                ]?.comment
              }
            </div>
          }
        /> :
        <Lightbox
          mainSrc={
            receiptNote.receipt_checklist[currentAssetIndex]?.receipt_media[
              lightboxImageIndex
            ]?.media_path
          }
          nextSrc={
            receiptNote.receipt_checklist[currentAssetIndex]?.receipt_media[
              (lightboxImageIndex + 1) %
                receiptNote.receipt_checklist[currentAssetIndex]?.receipt_media?.length
            ]?.media_path
          }
          prevSrc={
            receiptNote.receipt_checklist[currentAssetIndex]?.receipt_media[
              (lightboxImageIndex +
                receiptNote.receipt_checklist[currentAssetIndex]?.receipt_media
                  ?.length -
                1) %
                receiptNote.receipt_checklist[currentAssetIndex]?.receipt_media?.length
            ]?.media_path
          }
          onCloseRequest={() =>
            setIsOpen({ ...isOpen, [noteIndex]: false }) // Close lightbox for this note
          }
          onMovePrevRequest={() =>
            setLightboxImageIndex(
              (lightboxImageIndex +
                receiptNote.receipt_checklist[currentAssetIndex]?.receipt_media
                  ?.length -
                1) %
                receiptNote.receipt_checklist[currentAssetIndex]?.receipt_media?.length
            )
          }
          onMoveNextRequest={() =>
            setLightboxImageIndex(
              (lightboxImageIndex + 1) %
                receiptNote.receipt_checklist[currentAssetIndex]?.receipt_media?.length
            )
          }
          imageCaption={
            <div style={{ padding: "10px", color: "#fff" }}>
              {
                receiptNote.receipt_checklist[currentAssetIndex]?.receipt_media?.[
                  lightboxImageIndex
                ]?.comment || ""
              }
            </div>
          }
        />
      )}
      <table style={{marginTop: '20px'}}>
                <thead>
                <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Phone Number</th>
                    <th>Date</th>
                </tr>
                </thead>
                <tbody>
                <React.Fragment>
                    <tr>
                        <td style={{ verticalAlign: 'top' }}>
                        <b>Supervised By</b>
                        </td>
                        <td>{receiptNote.staff_details?.supervisor?.name}</td>
                        <td>{receiptNote.staff_details?.supervisor?.phone}</td>
                        <td>{parseTimeStamp(receiptNote.staff_details?.supervisor?.date)}</td>
                    </tr>
                    {(typeof receiptNote.staff_details?.tl_details === 'object' && receiptNote.staff_details?.tl_details !== null && !Array.isArray(receiptNote.staff_details?.tl_details)) ? <tr>
                        <td style={{ verticalAlign: 'top' }}>
                        <b>TL Details</b>
                        </td>
                        <td>{receiptNote.staff_details?.tl_details?.name}</td>
                        <td>{receiptNote.staff_details?.tl_details?.phone}</td>
                        <td>{parseTimeStamp(receiptNote.staff_details?.tl_details?.date)}</td>
                    </tr> : (Array.isArray(receiptNote.staff_details?.tl_details)) ? 
                        <React.Fragment>
                            <tr>
                                <td style={{ verticalAlign: 'top' }} rowSpan={receiptNote.staff_details?.tl_details?.length}>
                                <b>TL Details</b>
                                </td>
                                <td>{receiptNote.staff_details?.tl_details[0]?.name}</td>
                                <td>{receiptNote.staff_details?.tl_details[0]?.phone}</td>
                                <td>{parseTimeStamp(receiptNote.staff_details?.tl_details[0]?.date)}</td>
                            </tr>
                            {receiptNote.staff_details?.tl_details?.slice(1).map((tl, index) => 
                            <tr key={index}>
                                <td>{tl.name}</td>
                                <td>{tl.phone}</td>
                                <td></td>
                            </tr>
                            )}
                        </React.Fragment>
                    : null}
                    <tr>
                        <td style={{ verticalAlign: 'top' }}>
                        <b>Received By</b>
                        </td>
                        <td>{receiptNote.staff_details?.received_by?.name}</td>
                        <td>{receiptNote.staff_details?.received_by?.phone}</td>
                        <td>{receiptNote.staff_details?.received_by && parseTimeStamp(receiptNote.staff_details?.received_by?.date)}</td>
                    </tr>
                    </React.Fragment>
                </tbody>
            </table>

            {receiptNote.transportation && <table style={{marginTop: '20px'}}>
                <thead>
                <tr>
                    <th></th>
                    <th>Driver Name</th>
                    <th>Phone Number</th>
                    <th>Number Plate</th>
                </tr>
                </thead>
                <tbody>
                <React.Fragment>
                    {/* First Row: Asset Name and First Item */}
                    <tr>
                        <td rowSpan={receiptNote.transportation.length} style={{ verticalAlign: 'top' }}>
                        <b>Transportation</b>
                        </td>
                        <td>{receiptNote.transportation[0]?.driver_name}</td>
                        <td>{receiptNote.transportation[0]?.phone_number}</td>
                        <td>{receiptNote.transportation[0]?.vehicle_number_plate}</td>
                    </tr>
                    {/* Additional Rows: Remaining Items */}
                    {receiptNote.transportation.slice(1).map((transport, index) => (
                        <tr key={index}>
                        <td>{transport.driver_name}</td>
                        <td>{transport.phone_number}</td>
                        <td>{transport.vehicle_number_plate}</td>
                        </tr>
                    ))}
                    </React.Fragment>
                </tbody>
            </table>}
            </div>
  ))}
        
    </>
  )
}
