import React, {Suspense, lazy} from 'react'

const AssetBrandBlock = lazy(() => import("./AssetBrandBlock/AssetBrandBlock"));
const AssetAgenciesBlock = lazy(() => import("./AssetAgenciesBlock/AssetAgenciesBlock"));
const AssetClientBlock = lazy(() => import("./AssetClientBlock/AssetClientBlock"));
const AssetProjectsBlock = lazy(() => import("./AssetProjectsBlock/AssetProjectsBlock"));
const AssetCounts = lazy(() => import("./AssetCounts/AssetCounts"));

export function AssetDashboard() {

  return (
    <>
        <Suspense fallback={null}>
            <AssetCounts />
        </Suspense>
        <Suspense fallback={null}>
            <AssetClientBlock />
        </Suspense>
        <Suspense fallback={null}>
            <AssetAgenciesBlock />
        </Suspense>
        <Suspense fallback={null}>
            <AssetBrandBlock />
        </Suspense>
        <Suspense fallback={null}>
            <AssetProjectsBlock />
        </Suspense>
        {/* <div className="col-lg-6 col-xxl-12 mt-3" style={{minHeight: '350px'}}>
          <DashboardCardComponent isLoading={pageLoading} >
              <div className="card-header border-0 pt-2">
                <h5 className="card-title align-items-start flex-column mb-2">
                  <span className="fwt-bolder text-dark">Assets Per Project</span>
                </h5>
              <MainBarChart _data = {clientProjects} _value={'assets_count'} />
          </div>
          </DashboardCardComponent>
          </div> */}
        {/* <div className="col-lg-6 col-xxl-12 mt-3" style={{minHeight: '350px'}}>
          <DashboardCardComponent isLoading={pageLoading} >
              <div className="card-header border-0 pt-2">
                <h5 className="card-title align-items-start flex-column mb-2">
                  <span className="fwt-bolder text-dark">Asset Cost Per Project</span>
                </h5>
              <MainBarChart _data = {costPerProject}  _money={true} />
          </div>
          </DashboardCardComponent>
          </div> */}   
        </>

  )
}
