import RolesList from "../components/Authentication/Roles/RolesList";
import UserRoles from "../components/Authentication/UserRoles/UserRoles";
import DummyComponent from "../components/DummyComponent/DummyComponent";
import UserList from "../components/UserList/UserList";
import UserProfile from "../components/UserProfile/UserProfile";
import CreateUser from "../components/Authentication/Creatuser/CreatUser";
import CreateWarehouse from "../components/Warehouse/CreateWarehouse/CreateWarehouse";
import WarehouseList from "../components/Warehouse/WarehouseList/WarehouseList";
import DetailedWarehouse from "../components/Warehouse/DetailedWarehouse/DetailedWarehouse";
import CreateClientOrganization from "../components/ClientOrganization/CreateClientOrganization/CreateClientOrganization";
import ClientOrganizationsList from "../components/ClientOrganization/ClientOrganizationsList/ClientOrganizationsList";
import DetailedClientOrganization from "../components/ClientOrganization/DetailedClientOrganization/DetailedClientOrganization";
import CreateBrand from "../components/Brand/CreateBrand/CreateBrand";
import BrandsList from "../components/Brand/BrandsList/BrandsList";
import DetailedBrand from "../components/Brand/DetailedBrand/DetailedBrand";
import ToolManagement from "../components/Tools/ToolManagement";
import AccessoryManagement from "../components/Accessories/AccessoryManagement";
import Materials from "../components/Materials/Materials/Materials";
import CreateItem from "../components/Items/CreateItem/CreateItem";
import ItemsList from "../components/Items/ItemsList/ItemsList";
import DetailedItem from "../components/Items/DetailedItem/DetailedItem";
import CreateProject from "../components/Projects/CreateProject/CreateProject";
import DetailedProject from "../components/Projects/DetailedProject/DetailedProject";
import ProjectList from "../components/Projects/ProjectList/ProjectList";
import AssetList from "../components/Assets/AssetList/AssetList";
import CreateAsset from "../components/Assets/CreateAsset/CreateAsset";
import DetailedAsset from "../components/Assets/DetailedAsset/DetailedAsset";
import CreateActivation from "../components/Activations/CreateActivation/CreateActivation";
import DetailedActivation from "../components/Activations/DetailedActivation/DetailedActivation";
import ActivationsList from "../components/Activations/ActivationsList/ActivationsList";
import AgencyList from "../components/Agency/AgencyList/AgencyList";
import DetailedAgency from "../components/Agency/DetailedAgency/DetailedAgency";
import CreateAgency from "../components/Agency/CreateAgency/CreateAgency";
import AssetDashboard from "../components/Dashboards/ClientDashboards/AssetDashboard/AssetDashboard";
import ActivationDashboard from "../components/Dashboards/ClientDashboards/ActivationDashboard/ActivationDashboard";
import Dispatch from "../components/Activations/Dispatch/Dispatch";
import SubsequentDispatch from "../components/Activations/SubsequentDispatch/SubsequentDispatch";
import Receival from "../components/Activations/Receival/Receival";
import Locations from "../components/Activations/Locations/Locations";
import ActivationCalendar from "../components/Calendar/ActivationCalendar";
import { AssetDashboard as StaffAssetDashboard } from "../components/Dashboards/StaffDashboards/AssetDashboard/AssetDashboard";
import { ActivationDashboard as StaffActivationDashboard } from "../components/Dashboards/StaffDashboards/ActivationDashboard/ActivationDashboard";

// Define required permissions for each route
const privateRoutes = [
    { path: '/dummy-page', component: DummyComponent, permission: 'view_dummy_page' },

    // Staff
    // User Management
    { path: '/user-management/roles', component: RolesList, permission: 'view_group' },
    { path: '/user-management/role/:id', component: UserRoles, permission: 'view_group' },
    { path: '/user-management/users/:user_stat', component: UserList,  permission: 'view_user'},
    { path: '/user-management/users/user-profile/:id', component: UserProfile, permission: 'view_user_profile' },
    { path: '/user-management/invite-user', component: CreateUser, permission: 'can_invite_user' },

    // Warehouse Management
    { path: '/warehouse-management/create-warehouse', component: CreateWarehouse, permission: 'add_warehouse' },
    { path: '/warehouse-management/warehouses', component: WarehouseList, permission: 'view_warehouse' },
    { path: '/warehouse-management/warehouses/detailed-warehouse/:id', component: DetailedWarehouse, permission: 'view_detailed_warehouse' },

    // Client/Organization Management
    { path: '/client-management/clients', component: ClientOrganizationsList, permission: 'view_agency' },
    { path: '/client-management/create-client-organization', component: CreateClientOrganization, permission: 'add_agency' },
    { path: '/client-management/clients/detailed-client-organization/:id', component: DetailedClientOrganization, permission: 'view_detailed_agency' },

    // Agency Management
    { path: '/agency-management/agencies', component: AgencyList, permission: 'view_agency' },
    { path: '/agency-management/create-agency', component: CreateAgency, permission: 'add_agency' },
    { path: '/agency-management/agencies/detailed-agency/:id', component: DetailedAgency, permission: 'view_detailed_agency' },

    // Brand Management
    { path: '/brand-management/brands', component: BrandsList, permission: 'view_brand' },
    { path: '/brand-management/create-brand', component: CreateBrand, permission: 'add_brand' },
    { path: '/brand-management/detailed-brand/:id', component: DetailedBrand, permission: 'view_detailed_brand' },

    // Tool and accessory Management
    { path: '/tool-management/tools', component: ToolManagement, permission: 'view_tool' },
    { path: '/tool-management/accessories', component: AccessoryManagement, permission: 'view_accessory' },

    // Asset Management (Materials)
    { path: '/asset-management/materials', component: Materials, permission: 'view_material' },

    // Asset Management (Items)
    { path: '/asset-management/create-item', component: CreateItem, permission: 'add_item' },
    { path: '/asset-management/items', component: ItemsList, permission: 'view_item' },
    { path: '/asset-management/detailed-item/:id', component: DetailedItem, permission: 'view_detailed_item' },

    // Asset Management (Assets)
    { path: '/asset-management/create-asset', component: CreateAsset, permission: 'add_asset' },
    { path: '/asset-management/assets', component: AssetList, permission: 'view_asset' },
    { path: '/asset-management/detailed-asset/:id', component: DetailedAsset, permission: 'view_detailed_asset' },

    // Project Management
    { path: '/project-management/create-project', component: CreateProject, permission: 'add_project' },
    { path: '/project-management/projects', component: ProjectList, permission: 'view_project' },
    { path: '/project-management/detailed-project/:id', component: DetailedProject, permission: 'view_detailed_project' },

    // Activation Management
    { path: '/activation-management/create-activation', component: CreateActivation, permission: 'add_project' },
    { path: '/activation-management/activations', component: ActivationsList, permission: 'view_project' },
    { path: '/activation-management/detailed-activation/:id', component: DetailedActivation, permission: 'view_detailed_project' },


    // Client
    // Dashboards
    { path: '/asset-dashboard', component: AssetDashboard, permission: 'view_item' }, // add the permissions for this
    { path: '/activation-dashboard', component: ActivationDashboard, permission: 'view_item' }, // add the permissions for this
    
    // staff
    // Dashboards
    { path: '/main-asset-dashboard', component: StaffAssetDashboard, permission: 'view_item' }, // add the permissions for this
    { path: '/main-activation-dashboard', component: StaffActivationDashboard, permission: 'view_item' }, // add the permissions for this
    
    
    // Operations
    { path: '/activation-management/detailed-activation/dispatch/:id', component: Dispatch, permission: 'view_item' }, // add the permissions for this
    { path: '/activation-management/detailed-activation/subsequent-dispatch/:id', component: SubsequentDispatch, permission: 'view_item' }, // add the permissions for this
    { path: '/activation-management/detailed-activation/receival/:activation_id', component: Receival, permission: 'view_item' }, // add the permissions for this
    { path: '/locations', component: Locations, permission: 'view_item' }, // add the permissions for this
    { path: '/calendar', component: ActivationCalendar, permission: 'view_item' }, // add the permissions for this
];

export default privateRoutes;
